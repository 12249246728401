<template>
  <page-header-wrapper :showInModal="showInModal">
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper" v-if="!showInModal">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="账单流水号" prop="orderNo">
                <a-input
                  v-model="queryParam.orderNo"
                  placeholder="请输入账单流水号 唯一性，单号使用日期时间作为编号前面部分，最后4位系统流水生成"
                  allow-clear
                />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="日期时间">
                <a-range-picker
                  style="width: 100%"
                  v-model="daterangeCreateTime"
                  valueFormat="YYYY-MM-DD HH:mm:ss"
                  :showTime="showTime"
                  format="YYYY-MM-DD HH:mm:ss"
                  allow-clear
                />
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="用户账号" prop="userId">
                  <a-select
                    show-search
                    allowClear
                    placeholder="请输入用户账号模糊查找"
                    :default-active-first-option="false"
                    :show-arrow="false"
                    :filter-option="false"
                    :not-found-content="null"
                    @search="handleUserNameSearch($event, '2')"
                    @change="handleUserNameChange($event, '2')"
                    :loading="usloading"
                    v-model="queryParam.userId"
                  >
                    <a-select-option v-for="d in usersList" :key="d.userId" :value="d.userId">
                      {{ d.userName }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="登录智能秤的ID" prop="postStationUserId">
                  <a-select
                    show-search
                    :disabled="onlyRead"
                    allowClear
                    placeholder="请输入所属工作人员名称模糊查找"
                    :default-active-first-option="false"
                    :show-arrow="false"
                    :filter-option="false"
                    :not-found-content="null"
                    @search="handleUserNameSearch($event, '1')"
                    @change="handleUserNameChange($event, '1')"
                    :loading="hsloading"
                    v-model="queryParam.postStationUserId"
                  >
                    <a-select-option v-for="d in postStationList" :key="d.userId" :value="d.userId">
                      {{ d.userName }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="智能秤" prop="intelligentScaleId">
                  <a-select
                    show-search
                    allowClear
                    style="width: 100%"
                    placeholder="请输入智能秤ID模糊查找"
                    :default-active-first-option="false"
                    :show-arrow="false"
                    :filter-option="false"
                    :not-found-content="null"
                    @search="handledeviceSearch"
                    @change="handledeviceChange"
                    v-model="queryParam.intelligentScaleId"
                  >
                    <a-select-option v-for="(item, index) in deviceList" :key="index" :value="item.id">
                      {{ item.deviceId }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="驿站" prop="postStationId">
                  <a-select
                    show-search
                    allowClear
                    style="width: 100%"
                    placeholder="请输入驿站模糊查找"
                    :default-active-first-option="false"
                    :show-arrow="false"
                    :filter-option="false"
                    :not-found-content="null"
                    @search="handleQuerySearch"
                    @change="handleQueryChange"
                    v-model="queryParam.postStationId"
                  >
                    <a-select-option v-for="(item, index) in stationListQuery" :key="index" :value="item.id">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="状态" prop="orderSatus">
                  <a-select placeholder="请选择状态" v-model="queryParam.orderSatus" style="width: 100%" allow-clear>
                    <a-select-option v-for="(item, index) in orderSatusOptions" :key="index" :value="item.dictValue">{{
                      item.dictLabel
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1" v-if="!showInModal">
        <div class="table-operations">
          <a-button
            type="primary"
            @click="$refs.createForm.handleAdd()"
            v-hasPermi="['tenant:recyclable:recoveryBill:add']"
          >
            <a-icon type="plus" />新增
          </a-button>
          <a-button
            type="primary"
            :disabled="single"
            @click="$refs.createForm.handleUpdate(undefined, ids)"
            v-hasPermi="['tenant:recyclable:recoveryBill:edit']"
          >
            <a-icon type="edit" />修改
          </a-button>
          <a-button
            type="danger"
            :disabled="multiple"
            @click="handleDelete"
            v-hasPermi="['tenant:recyclable:recoveryBill:remove']"
          >
            <a-icon type="delete" />删除
          </a-button>
          <a-button type="primary" @click="handleExport"> <a-icon type="download" />导出 </a-button>
          <a-button type="primary" @click="handleExportImg"> <a-icon type="download" />图片批量下载 </a-button>
          <span class="operations-tips">（*汇总的金额只含“已付款”状态的账单）</span>
          <!-- <a-button
            type="dashed"
            shape="circle"
            :loading="loading"
            :style="{ float: 'right' }"
            icon="reload"
            @click="getList"
          /> -->
        </div>
      </a-toolbar>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
        :orderSatusOptions="orderSatusOptions"
        :settlementTypeOptions="settlementTypeOptions"
      />
      <div class="sum-wrap">
        <div class="sum-item">驿站数量总计：{{ sumResourcesInfo.countStation }}</div>
        <div class="sum-item">智能秤总计：{{ sumResourcesInfo.countScale }}</div>
        <div class="sum-item">账单金额总计：{{ sumResourcesInfo.totalAmount }}</div>
        <div class="sum-item">其中包含凑整：{{ sumResourcesInfo.totalRundUpAmount }}</div>
        <div class="sum-item">其中包含去杂：{{ sumResourcesInfo.totalImpurityAmount || 0 }}</div>
      </div>
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        tid="1"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
      >
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['tenant:recyclable:recoveryBill:list']" />
          <a
            @click="$refs.createForm.handleUpdate(record, undefined)"
            v-hasPermi="['tenant:recyclable:recoveryBill:list']"
          >
            <a-icon type="info-circle" />查看
          </a>
          <a-divider type="vertical" v-hasPermi="['tenant:recyclable:recoveryBill:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['tenant:recyclable:recoveryBill:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {
  listRecoveryBill,
  delRecoveryBill,
  exportRecoveryBill,
  sumResources,
  downlowndPic
} from '@/api/recyclable/recoveryBill'
import { search } from '@/api/recyclable/intelligentScale'
import CreateForm from './modules/CreateForm'
import { listWorkers } from '@/api/recyclable/users'
import { listAll } from '@/api/recyclable/postStationInfo'
import debounce from 'lodash/debounce'
import { settlementTypeOptions, orderSatusOptions } from './modules/options'
import moment from 'moment'

export default {
  name: 'RecoveryBill',
  components: {
    CreateForm
  },
  props: {
    showInModal: {
      type: Boolean,
      default: false
    },
    userInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    this.handleUserNameSearch = debounce(this.handleUserNameSearch, 500)
    this.handleQuerySearch = debounce(this.handleQuerySearch, 500)
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      stationListQuery: [],
      deviceList: [],
      daterangeCreateTime: [
        moment({ hour: 0, minute: 0, seconds: 0 }).format('YYYY-MM-DD HH:mm:ss'),
        moment({ hour: 23, minute: 59, seconds: 59 }).format('YYYY-MM-DD HH:mm:ss')
      ],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      usloading: false,
      total: 0,
      // 查询参数
      queryParam: {
        orderNo: null,
        userId: undefined,
        intelligentScaleId: undefined,
        postStationId: undefined,
        totalAmount: null,
        postStationUserId: undefined,
        settlementType: null,
        orderSatus: undefined,
        params: {
          startTime: null,
          endTime: null
        },
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: 'ID',
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '账单流水号',
          dataIndex: 'orderNo',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '时间',
          dataIndex: 'createTime',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '居民账号',
          dataIndex: 'userName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '智能秤设备号',
          dataIndex: 'deviceId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '登录智能秤的ID',
          dataIndex: 'createBy',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '所属驿站',
          dataIndex: 'stationName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '凑整后金额',
          dataIndex: 'totalAmount',
          ellipsis: true,
          align: 'center',
          customRender: (text, record, index) => {
            return record.totalAmount
          }
        },
        {
          title: '原始金额',
          dataIndex: 'originalAmount',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '价格模式',
          dataIndex: 'recoveryMode',
          ellipsis: true,
          align: 'center',
          customRender: (text, record, index) => {
            return this.tipMap[record.recoveryMode]
          }
        },
        {
          title: '结算方式',
          dataIndex: 'settlementType',
          ellipsis: true,
          align: 'center',
          customRender: (text, record, index) => {
            return this.optionsFormat(this.settlementTypeOptions, record.settlementType)
          }
        },
        {
          title: '状态',
          dataIndex: 'orderSatus',
          ellipsis: true,
          align: 'center',
          customRender: (text, record, index) => {
            return this.optionsFormat(this.orderSatusOptions, record.orderSatus)
          }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      settlementTypeOptions,
      orderSatusOptions,
      postStationList: [],
      sumResourcesInfo: {},
      tipMap: {
        1: '驿站',
        2: '上门',
        3: '自主设置'
      },
      showTime: { defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')] }
    }
  },
  filters: {},
  async created() {
    if (this.userInfo && this.userInfo.userName) {
      this.queryParam.userName = this.userInfo.userName
      await this.getListUsers(this.queryParam.userName)
      this.queryParam.userId = this.userInfo.userId && Number(this.userInfo.userId)
      this.queryParam.orderNo = this.userInfo.accountNo
    }
    this.getList()
  },
  computed: {},
  watch: {},
  methods: {
    getListUsers(name, type) {
      return new Promise((resolve, reject) => {
        listWorkers({
          userName: name,
          type: type
        }).then((response) => {
          if (type === '1') {
            this.postStationList = response.data
          } else {
            this.usersList = response.data
          }
          this.usloading = false
          resolve()
        })
      })
    },
    handleUserNameSearch(value, type) {
      console.log('handleUserNameSearch', value)
      this.usloading = true
      this.getListUsers(value, type)
    },
    handleUserNameChange(value, type) {
      console.log('handleUserNameChange', value)
    },
    /** 查询物品回收账单列表 */
    getList() {
      this.loading = true
      this.queryParam.params = {}
      if (
        this.daterangeCreateTime !== null &&
        this.daterangeCreateTime !== '' &&
        this.daterangeCreateTime.length !== 0
      ) {
        this.queryParam.params['startTime'] = this.daterangeCreateTime[0]
        this.queryParam.params['endTime'] = this.daterangeCreateTime[1]
      }
      listRecoveryBill(this.queryParam).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
      sumResources(this.queryParam).then((response) => {
        this.sumResourcesInfo = response.data
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.daterangeCreateTime = []
      this.queryParam = {
        orderNo: undefined,
        userId: undefined,
        intelligentScaleId: undefined,
        postStationId: undefined,
        totalAmount: undefined,
        postStationUserId: undefined,
        settlementType: undefined,
        orderSatus: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delRecoveryBill(ids).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          return exportRecoveryBill(that.queryParam).then((response) => {
            const fileName = response.headers['content-disposition'].split(';')[1].split('=')[1]
            that.download(fileName, 'excel', response.data)
            // that.download(response.msg, null, null, process.env.VUE_APP_HTTP_RC_ENDPOINT)
            that.$message.success('导出成功', 3)
          })
        },
        onCancel() {}
      })
    },
    handleExportImg() {
      var that = this
      let queryParam = {}
      queryParam = JSON.parse(JSON.stringify(this.queryParam))
      queryParam.pageNum = undefined
      queryParam.pageSize = undefined
      this.$confirm({
        title: '是否确认批量下载图片?',
        content: '此操作将下载当前条件下所有数据图片而非选中数据图片',
        onOk() {
          return downlowndPic(queryParam).then((response) => {
            if (response) {
              // const fileName = response.headers['content-disposition'].split(';')[1].split('=')[1]
              // that.downloadFile({ fileName, type: 'image', data: response.data })
              that.download(response.msg, null, null, process.env.VUE_APP_HTTP_RC_ENDPOINT)
              that.$message.success('导出成功', 3)
            }
          })
        },
        onCancel() {}
      })
    },
    optionsFormat(options, value) {
      return this.selectDictLabel(options, value)
    },
    handledeviceSearch(value) {
      this.deviceSearch(value)
    },
    handledeviceChange(value) {
      this.queryParam.intelligentScaleId = value
    },
    deviceSearch(deviceId) {
      search({
        deviceId
      }).then((res) => {
        this.deviceList = res.data
      })
    },
    handleQuerySearch(value) {
      console.log('handleQuerySearch', value)
      this.qloading = true
      this.listPostStationInfo(value, 'stationListQuery', 'qloading')
    },
    handleQueryChange(value) {
      console.log('handleQueryChange', value)
      this.queryParam.postStationId = value
    },
    listPostStationInfo(name, arrName, loadingName) {
      listAll({
        stationName: name
      }).then((res) => {
        this[loadingName] = false
        this[arrName] = res.data
      })
    }
  }
}
</script>
<style lang="less" scoped>
.sum-wrap {
  display: flex;
  padding: 15px 0;
  .sum-item {
    margin-right: 30px;
    font-size: 14px;
    color: #000000d9;
  }
}
.operations-tips {
  font-size: 15px;
  position: relative;
  top: 5px;
  color: red;
}
</style>
