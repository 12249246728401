/*
 * @Auth: linjituan
 * @Date: 2022-03-14 10:21:14
 * @LastEditors: linjituan
 * @LastEditTime: 2022-03-17 20:27:44
 */
import request from '@/utils/request'
const baseURL = '/rc-api'
// 查询驿站信息列表
export function listPostStationInfo(query) {
  return request({
    url: '/internal/recyclable/postStationInfo/list',
    method: 'get',
    baseURL,
    params: query
  })
}

// 查询驿站信息列表所有
export function listAll(query) {
  return request({
    url: '/internal/recyclable/postStationInfo/listAll',
    method: 'get',
    baseURL,
    params: query
  })
}

// 查询驿站信息详细
export function getPostStationInfo(id) {
  return request({
    url: '/internal/recyclable/postStationInfo/' + id,
    baseURL,
    method: 'get'
  })
}

// 新增驿站信息
export function addPostStationInfo(data) {
  return request({
    url: '/internal/recyclable/postStationInfo',
    baseURL,
    method: 'post',
    data: data
  })
}

// 修改驿站信息
export function updatePostStationInfo(data) {
  return request({
    url: '/internal/recyclable/postStationInfo',
    baseURL,
    method: 'put',
    data: data
  })
}

// 删除驿站信息
export function delPostStationInfo(id) {
  return request({
    url: '/internal/recyclable/postStationInfo/' + id,
    baseURL,
    method: 'delete'
  })
}

// 导出驿站信息
export function exportPostStationInfo(query) {
  return request({
    url: '/internal/recyclable/postStationInfo/export',
    baseURL,
    method: 'get',
    params: query
  })
}

// 绑定智能秤
export function bindScale(query) {
  return request({
    url: '/internal/recyclable/postStationInfo/bindScale/' + query.id,
    baseURL,
    method: 'get',
    params: query
  })
}

// 解绑智能秤
export function unBindScale(deviceId) {
  return request({
    url: '/internal/recyclable/postStationInfo/unBindScale/' + deviceId,
    baseURL,
    method: 'get'
  })
}
