/*
 * @Auth: linjituan
 * @Date: 2022-03-14 10:21:14
 * @LastEditors: linjituan
 * @LastEditTime: 2022-03-25 18:11:19
 */
import request from '@/utils/request'
const baseURL = '/rc-api'
// 查询智能秤信息列表
export function listIntelligentScale(query) {
  return request({
    url: '/internal/recyclable/intelligentScale/list',
    baseURL,
    method: 'get',
    params: query
  })
}

// 查询智能秤信息详细
export function getIntelligentScale(id) {
  return request({
    url: '/internal/recyclable/intelligentScale/' + id,
    baseURL,
    method: 'get'
  })
}

// 新增智能秤信息
export function addIntelligentScale(data) {
  return request({
    url: '/internal/recyclable/intelligentScale',
    baseURL,
    method: 'post',
    data: data
  })
}

// 修改智能秤信息
export function updateIntelligentScale(data) {
  return request({
    url: '/internal/recyclable/intelligentScale',
    baseURL,
    method: 'put',
    data: data
  })
}

// 删除智能秤信息
export function delIntelligentScale(id) {
  return request({
    url: '/internal/recyclable/intelligentScale/' + id,
    baseURL,
    method: 'delete'
  })
}

// 导出智能秤信息
export function exportIntelligentScale(query) {
  return request({
    url: '/internal/recyclable/intelligentScale/export',
    baseURL,
    method: 'get',
    params: query
  })
}

// 锁定智能秤
export function lock(id) {
  return request({
    url: '/internal/recyclable/intelligentScale/lock/' + id,
    baseURL,
    method: 'get'
  })
}

// 解锁智能秤
export function unlock(id) {
  return request({
    url: '/internal/recyclable/intelligentScale/unlock/' + id,
    baseURL,
    method: 'get'
  })
}

// 解锁智能秤
export function logininfor(deviceId) {
  return request({
    url: '/internal/recyclable/logininfor/list/' + deviceId,
    baseURL,
    method: 'get'
  })
}

// 绑定驿站
export function bindStation(data) {
  return request({
    url: '/internal/recyclable/intelligentScale/bindStation',
    baseURL,
    method: 'post',
    data: data
  })
}

// 未绑定到驿站的智能秤列表
export function unBindList(id) {
  return request({
    url: '/internal/recyclable/intelligentScale/unBindList',
    baseURL,
    method: 'get'
  })
}

// 根据智能秤deviceId模糊搜索
export function search(query) {
  return request({
    url: '/internal/recyclable/intelligentScale/search',
    baseURL,
    method: 'get',
    params: query
  })
}
